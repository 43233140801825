$("#product-compare").hide();
$("#compare-close-btn").click(function () {
    $('#comapare-panel').animate({bottom: '-478px'});
});
$(function () {

    $('.compare-button').click(function () {

        $.ajaxSetup(
            {
                headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
            });

        $.ajax(
            {
                type: 'get',
                dataType: 'html',
                url: '/compare-product-review',
                success: function (compare_html) {
                    if (!compare_html) {
                        $("#product-compare").hide();
                        $('#comapare-panel').css('bottom', '-478px');
                        return;
                    }
                    showCompare(compare_html);
                    $('#comapare-panel').animate({bottom: '0px'});
                }
            });
    });

    function showCompare(html) {
        $("#product-compare").show();

        $("#compare-body").fadeOut(100, function () {
            $(this).html(html);


            $(this).fadeIn(100, function () {
                resizeCompareProducts();
            });

        });
    }

    $(document).on('click', ".compare-close", function () {
        getCompare($(this).val(), false);
        $("#compare_" + $(this).val()).iCheck('uncheck');
        $('.compare-button').first().click();
    });

    function getCompare(product_id, checked) {
        if (checked) {
            $.ajaxSetup(
                {
                    async: false,
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
        }
        $.ajax(
            {
                type: 'post',
                url: checked ? '/compare-product-add' : '/compare-product-remove',
                data: {
                    id: product_id
                }
            });
    }

    $('.compare input[type="checkbox"]').on('ifChanged', function (event) {
        let product_id = $(this).attr('id').split('_')[1];
        getCompare(product_id, event.target.checked);
        $('.compare-button').first().click();
    });

    function resizeCompareProducts() {
        let th = 0;
        let ch = 0;
        let ih = 0;
        $("#product-compare h4.title, #product-compare div.intro, #product-compare .icon-container").css("height", "auto");

        $("#product-compare .product-compare-item").each(
            function () {
                var tth = $(this).find("h4.title").outerHeight();
                if (tth > th) {
                    th = tth;
                }
                var tch = $(this).find("div.intro").outerHeight();
                if (tch > ch) {
                    ch = tch;
                }
                var ich = $(this).find("div.icon-container").outerHeight();
                if (ich > ih) {
                    ih = ich;
                }
            }
        );

        $("#product-compare h4.title").outerHeight(th);
        $("#product-compare div.intro").outerHeight(ch);
        $("#product-compare div.icon-container").outerHeight(ih);
    }


    $('.i-ask-the-delivery-time').click(function () {
        var name = $(this).closest('.row').parent().closest('.row').find('h3').text();
        var product_id = $(this).attr('data-id');
        $('#modal-product-name').text(name);
        $('.modal-product-id').val(product_id);
    });
});
